//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import mockSrv from "@/api/mockSrv";
export default {
  components: {
    Header: () => import("@/components/Header"),
    CContent: () => import("@/components/CContent"),
    CTitle: () => import("@/components/CTitle"),
    CPages: () => import("@/components/CPages"),
    ProductInfo: () => import("./productInfo"),
  },
  data: () => ({
    mockData: false,
    loading: false,
    search: {
      name: "",
      isMarketable: null,
    },
    tableData: [],
    titleInfo: {
      btnShow: [
        { type: "addBtn", ishow: true },
        { type: "startBtn", ishow: false, disabled: true },
        { type: "closeBtn", ishow: false, disabled: true },
        { type: "delBtn", ishow: false, disabled: true },
      ],
      dropDown: {
        drop_2: {
          placeholder: "上架状态",
          clear: true,
          options: [
            {
              label: "已上架",
              value: true,
            },
            {
              label: "已下架",
              value: false,
            },
          ],
        },
        searchInput: {
          clearable: true,
          placeholder: "商品名称/商品码",
        },
      },
    },
    pageData: {
      pageIndex: 1,
      pageSize: 10,
      totalNum: 0,
    },
    indexData: {
      type: "", // Add Edit ""
      name: "",
      row: null,
      xModalName: "productInfo",
    },
  }),
  filters: {
    truncateText(text) {
      if (!text) {
        return "无";
      }
      const maxWords = 80;
      if (text.length > maxWords) {
        text = text.substring(0, maxWords) + "...";
      }
      return text;
    },
  },
  methods: {
    handleAdd() {
      this.openWin("ad");
    },
    /**
     * 编辑
     */
    handleView(row) {
      this.openWin("ed", row);
    },
    drop2Change(isMarketable) {
      if (isMarketable == -1 || isMarketable == "") {
        isMarketable = null;
      }
      this.search.isMarketable = isMarketable;
    },
    searchClick(search) {
      this.search.name = search;
      this.getDataList();
    },
    handlePaginationChange(val) {
      this.pageData = val;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      var params = {
        name: this.search.name,
        isMarketable: this.search.isMarketable,
        total: 200,
        pageIndex: this.pageData.pageIndex,
        pageSize: this.pageData.pageSize,
      };
      if (this.mockData) {
        mockSrv.productList(params).then((resp) => {
          if (resp.res == 0) {
            this.tableData = resp.data.Data;
            this.pageData.totalNum = resp.data.Total;
          }
        });
      } else {
        this.$http
          .post("/Product/List.ashx", params)
          .then((resp) => {
            if (resp.res == 0) {
              this.tableData = resp.data.Data;
              this.pageData.totalNum = resp.data.Total;
            }
          })
          .catch((err) => {
            this.$message({
              message: `获取商品列表失败`,
              type: "error",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    updateFreeClientFunctions() {
      this.$confirm("此操作将同步免费版团队功能, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          this.$http
            .post("/Product/UpdateFreeClientFunctions.ashx")
            .then((resp) => {
              if (resp.res == 0) {
                this.$message({
                  message: `同步客户端功能成功`,
                  type: "success",
                });
                this.getDataList();
              }
            })
            .catch((err) => {
              this.$message({
                message: `同步客户端功能失败`,
                type: "error",
              });
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消同步",
          });
        });
    },
    updateClientFunctions(product) {
      this.$confirm("此操作将同步该商品绑定的团队功能, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          var params = {
            Id: product.Id,
          };
          this.$http
            .post("/Product/UpdateClientFunctions.ashx", params)
            .then((resp) => {
              if (resp.res == 0) {
                this.$message({
                  message: `同步客户端功能成功`,
                  type: "success",
                });
                this.getDataList();
              }
            })
            .catch((err) => {
              this.$message({
                message: `同步客户端功能失败`,
                type: "error",
              });
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消同步",
          });
        });
    },
    deleteProduct(product) {
      this.$confirm("此操作将永久删除该商品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          var params = {
            Id: product.Id,
          };
          this.$http
            .post("/Product/Delete.ashx", params)
            .then((resp) => {
              if (resp.res == 0) {
                this.$message({
                  message: `删除商品成功`,
                  type: "success",
                });
                this.getDataList();
              }
            })
            .catch((err) => {
              this.$message({
                message: `删除商品失败`,
                type: "error",
              });
            })
            .finally(() => {
              this.loading = false;
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    changeMarketable(product) {
      this.loading = true;
      var params = {
        Id: product.Id,
        isMarketable: !product.IsMarketable,
      };
      this.$http
        .post("/Product/ChangeMarketable.ashx", params)
        .then((resp) => {
          if (resp.res == 0) {
            product.IsMarketable = !product.IsMarketable;
            this.$message({
              message: (product.IsMarketable ? "上架" : "下架") + `成功`,
              type: "success",
            });
          }
        })
        .catch((err) => {
          this.$message({
            message: `操作失败`,
            type: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 打开窗口
    openWin(ty, obj) {
      var type = "";
      if (ty != "") {
        type = ty === "ad" ? "Add" : "Edit";
      }
      this.indexData.type = type;
      if (ty != "ad") {
        this.indexData.row = obj;
      } else {
        this.indexData.row = null;
      }
      this.$modal.show("productInfo");
      this.$nextTick(() => {
        this.$refs.productInfo.changeEditState();
      });
    },
  },
  mounted() {
    this.getDataList();
  },
};
